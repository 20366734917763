/* Adds the class profile-header__secondary-affiliations--show-all
to the grandparent of the button clicked */

const setAffiliationExpand = (elementButtons: NodeListOf<Element> | null): void => {
  if (elementButtons) {
    elementButtons.forEach((elementButton) => {
      elementButton?.addEventListener('click', (event: Event) => {
        event.stopPropagation();
        const isExpanded = elementButton?.getAttribute('aria-expanded') === 'true';
        elementButton?.setAttribute('aria-expanded', isExpanded ? 'false' : 'true');
        const target: HTMLElement | null = event.target as HTMLElement;
        if (target) {
          const grandparent: HTMLElement | null | undefined = target.parentElement?.parentElement;
          if (grandparent) {
            const hasClass = grandparent?.classList.contains('profile-header__secondary-affiliations--show-all');
            if (hasClass) {
              grandparent.classList.remove('profile-header__secondary-affiliations--show-all');
            } else {
              grandparent.classList.add('profile-header__secondary-affiliations--show-all');
            }
          }
        }
      });
      document.addEventListener('click', (event: Event) => {
        const target: HTMLElement | null = event.target as HTMLElement;
        if (target) {
          const isInsideAffiliate = target.closest(".profile-header__secondary-affiliations__content")
          if (!isInsideAffiliate) {
            elementButtons.forEach((elementButton) => {
              const grandparent: HTMLElement | null | undefined = elementButton.parentElement?.parentElement;
              if (grandparent?.classList.contains('profile-header__secondary-affiliations--show-all')) {
                grandparent.classList.remove('profile-header__secondary-affiliations--show-all');
              }
            });
          }
        }
      });
    });
  }
};

export default setAffiliationExpand;
