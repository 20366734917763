import { setContentToggle } from '../components/content-toggle';
import { setHorizontalScroll, setScrollbarWidthVariable } from '../components/helpers';
import { setCopyToClipboard } from '../components/nav';
import copyHexCode from '../components/copy-hex-code';
import ready from '../components/ready';
import { setTabs } from '../components/tabs';
import { setTruncate } from '../components/truncator';
import setAffiliationExpand from '../components/affiliate-expand';
import { horizontalScrollButtonNext, horizontalScrollButtonPrevious } from '../components/horizontal-scroll-btn';
import { setupAutoExpand } from '../components/text-area';
import { charCounter } from '../components/char-counter';
import { dropdownIconToggle } from '../components/form';
import { stepperLabelsCenter } from '../components/stepper';

let windowInnerWidth = 0;
ready(() => {
  const elementButtons = document.querySelectorAll('.profile-header__secondary-affiliations__overflow-toggle');
  setAffiliationExpand(elementButtons);

  const tabbedContent = document.querySelectorAll('.tabbed-content');
  const tabbedObjects: ITabbedObject[] = [];
  tabbedContent.forEach((tab) => tabbedObjects.push({
    domElement: tab,
    rendered: false,
  }));
  setTabs(tabbedObjects);

  // Character counter for form input
  const elements = document.querySelectorAll('.form__field__character-count');
  elements.forEach((element) => {
    charCounter(element as HTMLElement);
  });

  // Textarea input
  const textarea = document.getElementById("autoexpand") as HTMLTextAreaElement | null;
  setupAutoExpand(textarea);
  window.onload = function () {
    setupAutoExpand(textarea);
  };

  // Centers stepper labels when they are 4 chars or less
  const stepperLabels: NodeListOf<HTMLElement> = document.querySelectorAll('.stepper__item__label');
  stepperLabels.forEach((element) => {
    stepperLabelsCenter(element);
  });


  // Dropdown box icon toggle
  // const dropDownElements: NodeListOf<HTMLElement> = document.querySelectorAll('.form__field select, .form__field__combo-box__field');
  // dropDownElements.forEach((element) => {
  //   dropdownIconToggle(element);
  // });

  const ctc = document.querySelectorAll('.copy-to-clipboard');
  ctc.forEach((element) => setCopyToClipboard(element as HTMLElement, document.querySelector('html')?.getAttribute('lang') === 'en' ? 'Hexcode copied!' : 'Hexkode kopieret!'));
  copyHexCode();

  const toggles = document.querySelectorAll('.box .content-toggle__content, .profile-header .content-toggle__content, .design-documentation .content-toggle__content');
  toggles.forEach((toggle) => {
    setContentToggle(toggle);
  });

  // Hortizontal scroll buttons
  const scrollButtonsNext = document.querySelectorAll('.button.button--small.button--icon.button--icon--hide-label.icon-next');
  const scrollButtonsPrevious = document.querySelectorAll('.button.button--small.button--icon.button--icon--hide-label.icon-previous');
  horizontalScrollButtonNext(scrollButtonsNext);
  horizontalScrollButtonPrevious(scrollButtonsPrevious);

  const horizontalScroll = document.querySelectorAll('.horizontal-scroll');
  const switchSegments = document.querySelectorAll('.switch__segment');
  horizontalScroll.forEach(hs => {
    setHorizontalScroll(hs as HTMLElement);
    window.addEventListener('resize', () => {
      setHorizontalScroll(hs as HTMLElement);
    });
    switchSegments.forEach(segment => {
      segment.addEventListener('click', () => {
        setHorizontalScroll(hs as HTMLElement);
      });
    });
  });

  const truncators = document.querySelectorAll('.truncator');
  truncators.forEach((truncator) => setTruncate(truncator as HTMLElement));

  document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight}px`);
  setScrollbarWidthVariable();

  window.addEventListener('load', () => {
    windowInnerWidth = window.innerWidth;
  });

  window.addEventListener('resize', () => {
    if (windowInnerWidth > 0 && window.innerWidth !== windowInnerWidth) {
      windowInnerWidth = window.innerWidth;
      setTabs(tabbedObjects);
    }
  });
});
