function setupCharacterCount(
    element: HTMLElement,
    type: HTMLTextAreaElement | HTMLInputElement | null,
    length: string | null,
    countdown: HTMLElement | null,
    countMessageOverflow: HTMLElement | null
    ): void {
    let text = type?.value;
    let charCount = text?.length;
      if (type && text) {
        type.textContent = text;
        charCount = text?.length;
        if (charCount && countdown && countMessageOverflow) {
            if (length) {
            const lengthNumber = parseInt(length, 10);
            let remainingChars = lengthNumber - charCount;
            let absoluteValue = Math.abs(remainingChars);
            // Update span 2 with remaining number of chars
            if(countMessageOverflow.textContent) {
              countMessageOverflow.textContent = countMessageOverflow.textContent.replace(/\d+/, String(absoluteValue));
            }
            // Update span 3 with remaining number of chars
            if(countdown.textContent) {
              countdown.textContent = countdown.textContent.replace(/\d+/, String(absoluteValue));
            }
            // Adds overflow class if remaining chars < 0 
            if (remainingChars < 0) {
              element.classList.add('form__field__character-count--overflow');
              element.setAttribute("aria-invalid", "true");
              }
            // Remove overflow class if remaining chars >= 0 
            if (remainingChars >= 0) {
              element.classList.remove('form__field__character-count--overflow');
              element.setAttribute("aria-invalid", "false");
              }
            }
          }
      }
  }

  function charCounter(element: HTMLElement | null): void { 
    if (element)  {
        const textArea = element.querySelector("textarea");
        const input = element.querySelector("input");
        const recommendedLength = element.getAttribute('data-recommended-length');
        const allowedLength = element.getAttribute('data-allowed-length');
        let countdown = element.querySelector('.form__field__character-count__info__count-down');
        let countMessageOverflow = element.querySelector('.form__field__character-count__info__overflow-count');

        // Textarea intial page load
        setupCharacterCount(element as HTMLElement, textArea as HTMLTextAreaElement, recommendedLength as string, countdown as HTMLElement, countMessageOverflow as HTMLElement);
        setupCharacterCount(element as HTMLElement, textArea as HTMLTextAreaElement, allowedLength as string, countdown as HTMLElement, countMessageOverflow as HTMLElement);

        // Input intial page load
        setupCharacterCount(element as HTMLElement, input as HTMLInputElement, recommendedLength as string, countdown as HTMLElement, countMessageOverflow as HTMLElement);
        setupCharacterCount(element as HTMLElement, input as HTMLInputElement, allowedLength as string, countdown as HTMLElement, countMessageOverflow as HTMLElement);

        // Update textarea on input
        textArea?.addEventListener('input', function() {  
        setupCharacterCount(element as HTMLElement, textArea as HTMLTextAreaElement, recommendedLength as string, countdown as HTMLElement, countMessageOverflow as HTMLElement);
        setupCharacterCount(element as HTMLElement, textArea as HTMLTextAreaElement, allowedLength as string, countdown as HTMLElement, countMessageOverflow as HTMLElement);
        })

        // Update input field on input
        input?.addEventListener('input', function() {  
        setupCharacterCount(element as HTMLElement, input as HTMLInputElement, recommendedLength as string, countdown as HTMLElement, countMessageOverflow as HTMLElement);
        setupCharacterCount(element as HTMLElement, input as HTMLInputElement, allowedLength as string, countdown as HTMLElement, countMessageOverflow as HTMLElement);
        })
    }
  }

  export {
    charCounter,
    setupCharacterCount
  };