// Centers stepper labels if they are 4 chars or less
function stepperLabelsCenter(element: HTMLElement): void { 
        if (element && element.textContent) {
          if (element.textContent.length <= 4) {
            element.style.textAlign = 'center';
          }
        }
}

export {
    stepperLabelsCenter
  };
  